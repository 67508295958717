export let data = [
  '{"name": "Anusha Yeruva", "place": "Hyderbad", "waNumber": "917036449449", "email": "anshuyeruva@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a114c6a45e36895d6546cfc944140aeef7410bd5bc6f78270a744e149b632689/Screenshot_20210521_195038_Photos_2.jpg", "scribbleSpace": "I am intrested in sustainable living.\\n\\nI love nature walks, take fresh air and organic diet,  which helps me to he fit and healthy.\\n\\nI am looking forward to know myself and my intrests with the help of this club.", "contribution": "I am not sure yet, but I can motivate people by my writings, yet looking forward to do something new which can help me know myself well.", "intro": "I am a yoga practitioner and  a nature lover."}',
  '{"name": "Manisha Yadav", "place": "Tavarekere, Bangalore", "waNumber": "918750545040", "email": "maniphilo1202@gmail.com", "photoLine": "https://api.typeform.com/responses/files/191dbef30210ea527256f3aafc4af0ddebda56bac6631984739ced10a8d79eac/IMG20210422130114.jpg", "scribbleSpace": "Looking forward to developing the wake-up habit and learn more about myself. A way to move one step near to oneself.", "contribution": "A few motivational quotes about maintaining health.", "intro": "Philosopher"}',
  '{"name": "Dhaval (\\u0927\\u0935\\u0932)", "place": "Domlur, Bangalore", "waNumber": "918375029336", "email": "dhavalchudasama@gmail.com", "photoLine": "https://api.typeform.com/responses/files/316f3dd9b2b6b3501f624106e89fbf56e36735db2832bae6b5b11a8206f10b75/A46B3B3E_8DD7_4F07_9E33_14CE65415628_1_201_a.jpeg", "scribbleSpace": "https://dhavalchudasama.com/", "contribution": "Let\'s co-learn during the June camp journey :)", "intro": "Curious Traveller"}',
  '{"name": "Shilpa Gaur", "place": "Makrana Rajasthan", "waNumber": "919794378287", "email": "shilpa020489@gmail.com", "photoLine": "https://api.typeform.com/responses/files/78c34a36457b354f74fc0d396098e0aaa08f3e6d6e7f0c8746ea785584143142/E0D59C16_1EB9_45FC_9C1D_BB89F55CDE93.jpeg", "scribbleSpace": "Reading, Want to get fit and get more done everyday", "contribution": "Functionally I can help in marketing and strategy related things. I work with a gaming studio so anyone looking to understand about this area can connect with me. I can help buddy up with people in micro projects for a week ( like finishing a small online course or book in a week or small sprint like not eating junk etc). I can be a good responsibility partner.", "intro": "Habitual Reader"}',
  '{"name": "Umesh Kedari", "place": "Katraj Pune", "waNumber": "919850355691", "email": "umesh.kedari@gmail.com", "photoLine": "https://api.typeform.com/responses/files/72e716e345216a58fdd9f46ef50ef577fba33868fb139f3cea7bbaeb285ae1e1/IMG_20200521_094042.jpg", "scribbleSpace": "Listening Indian classical music, Gardening", "contribution": "Experience", "intro": "Mechanical Engineer"}',
  '{"name": "Aaditya Goyal", "place": "Panchkula Haryana", "waNumber": "918619883663", "email": "goyal.aaditya@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9d9d7d85af28d03409f9c73d6f174920d25a7b33d977989ab4d0686707e80535/Screenshot_20210531_160754.png", "scribbleSpace": "I like to read fiction. Learn new languages. Learn new technology. Trying to find the next wave to work on.", "contribution": "Start-ups, entrepreneurship and Technology", "intro": "Entrepreneur"}',
  '{"name": "Pallavi Dhoot", "place": "Senapati Bapat Marg, Pune", "waNumber": "919822008007", "email": "pallavidhoot.nature@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f562524e23ca1314e06cf69db8e1daa6423eb810c7330f9255f984df68d901fa/Screenshot_20210104_042823.png", "scribbleSpace": "I am an early riser.But getting up every day exactly at the same time is something I am trying for the first time.  A dedicated mother and wife, a passionate Naturopath and researcher, a sustainable lifestyle explorer, is how I describe myself.", "contribution": "Can share my knowledge, thoughts and experiences", "intro": "An Explorer"}',
  '{"name": "Gunjan Aggarwal", "place": "HSR Layout, Bangalore", "waNumber": "919833263624", "email": "gunjan1982@gmail.com", "photoLine": "https://api.typeform.com/responses/files/be5549086170725cb10d3cf7861b268a8976e8601ae8ad98b0d8fe8ceb009a3f/1622406352148681389425.jpg", "scribbleSpace": "Bookworm and movie geek", "contribution": "A new crypto enthusiast", "intro": "Crypto geek"}',
  '{"name": "Preetee pushpa-prakash", "place": "Aundh pune", "waNumber": "919422517129", "email": "opreetee@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ebd6b848a3d5722d9671716bdfe75fed1990fd965c18b6dca6d35085e585e187/IMG20190706174944.jpg", "scribbleSpace": "Gardening, composting, parenting, writing, storytelling, reading, education, environment, biodiversity", "contribution": "How to do the wet waste composting at home", "intro": "Life-long learner"}',
  '{"name": "Abhishek Anand", "place": "Delhi NCR", "waNumber": "918800373555", "email": "abhishek.x.anand@iiml.org", "photoLine": "https://api.typeform.com/responses/files/9035e146cb216cdb148787bbc0bdb03f58a48f1f14d29c68fd07eeb9ed32074a/Screenshot_20210101_214311_01.jpeg", "scribbleSpace": "Photography, reading. Love to explore the artistic side.", "contribution": "Will think through", "intro": "Love photography."}',
  '{"name": "Raman Bhatia", "place": "Gurgaon", "waNumber": "919654135441", "email": "toramanbhatia@gmail.com", "photoLine": "https://api.typeform.com/responses/files/68e28ba37cc301b96331af279a8c37f30a9b3c5f3b387ae7c2cd97e34142e624/9C67A19D_99B6_4669_A3A0_5C1A64CC2BB9.jpeg", "scribbleSpace": "Better be awake till 5 am", "contribution": "Life is short, make the most of it", "intro": "Sleep Lover"}',
  '{"name": "Swati More", "place": "Sinhagad road,Pune", "waNumber": "919881476149", "email": "swatishirish16@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9411e665552a944bda6dc6f3be1ef71173a617b5e33b8e65b9222e32d0ebf49c/IMG_20210531_WA0047__01.jpg", "scribbleSpace": "Interests in drawing, painting, gardening and trying to learn new things.", "contribution": "Not sure at this moment", "intro": "Academician"}',
  '{"name": "Snehal Bari", "place": "Handewadi Pune", "waNumber": "919284761691", "email": "barisnehal29@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c31190b01f50d1ba3320f53e57c67d19a341ed336ddd15a358cc5756f63a0a12/IMG_20210324_WA0011.jpg", "scribbleSpace": "I like to listen to the people than to speak\\nSelf talkative and over thinking girl\\nNot a usual girl\\nCan be aggressive anytime\\nI love being fit\\nLifetime learner", "contribution": "I can motivate people to be fit", "intro": "Yoga practitioner"}',
  '{"name": "Leena Patil", "place": "Mumbai Mulund", "waNumber": "919820383123", "email": "leenapatil@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/93a120549a166b0cdf3cd71a92e8de0656c5da275fe09b00e2a8508317a87d1a/IMG_20170505_124933424_HDR.jpg", "scribbleSpace": "Optimistic!!!!", "contribution": "Happy to help!!!", "intro": "Lawyer!!!!!"}',
  '{"name": "Poorva Joshi", "place": "Sinhagad road Pune", "waNumber": "919604289893", "email": "poorva98joshi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/89693ff9aa19bef17de05ffd6d528b5506b4bd228400386cd88677b8e0d009f4/Polish_20210531_075103985.png", "scribbleSpace": "I\'m a medical student , studying Ayurveda. I love to sing and listen to music . Developed a newfound interest in macrame this lockdown. I also do a little bit of trekking and travelling ( of course not during the pandemic) . I\'ve been a member of this Club in May and I can say I have developed the habit of waking up at 5am . I love the energy that the group gives in those 15 mins in the morning , gives a positive start to the day!", "contribution": "Can give some health related tips", "intro": "Ayurveda student"}',
  '{"name": "Renu Shirokha", "place": "Mumbai", "waNumber": "919004087387", "email": "rshirokha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6f0139a6cdcff3e995d15abce2ab2cf40095526fd4c2e1819956cce7af0ccb58/603BFBEB_4733_4023_B4C8_C84F32DD982A.jpeg", "scribbleSpace": "I m architect as well as artist. My hobbies are reading, travelling and playing with my dog. My plans are concentrate my growth in self development as much as possible got this year.. looking forward to meet you all soon", "contribution": "Well, i would like share the more we look within ourself .. everything falls on it places on outside", "intro": "Architect & artist"}',
  '{"name": "Balaji Mankari", "place": "Banglore", "waNumber": "918125864262", "email": "balajimankari2015@gmail.com", "photoLine": "https://api.typeform.com/responses/files/630687bab5f99e0d0dddf9472da99885a9ed8b39686968514efae5dadd566493/101EFF09_70E8_481A_A4FC_20B8CF327B82.jpeg", "scribbleSpace": "I am director of educational institution and In my free time I will be travelling to Himalayas and I love road trips", "contribution": "Let c what I can contribute", "intro": "Traveller"}',
  '{"name": "Shrravane Vuyyuru", "place": "Chennai", "waNumber": "919884983213", "email": "shrravane21@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ae62a34c34267b3f0e283b300e97b89a18a2aa3ade3583cc451b0622dab5bfc2/32ED1DC0_F49C_4FE4_8ED2_C702EE712C1D.jpeg", "scribbleSpace": "Just another happy go lucky girl, finding new ways to improve and be the best version of myself.\\nalso, someone who has an appetite for art.", "contribution": "Just looking forward to learn from all you guys,", "intro": "CA aspirant!"}',
  '{"name": "Kalpesh Dhruve", "place": "Pimple Saudagar, Pune", "waNumber": "919923145886", "email": "kdhruve2@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ea83a0c26ddf8b60eb285cbadb84e08531c0a59f7fa76597a26ece43fc7106a6/20210102_120936.jpg", "scribbleSpace": "Outdoor Sports, Making short phrases/Rhymes, Working towards redeeming myself", "contribution": "Financial, Investment Advisory,", "intro": "Practising Chartered Accountant"}',
  '{"name": "Hema", "place": "Mumbai", "waNumber": "919004261133", "email": "hema.sagare@gmail.com", "photoLine": "https://api.typeform.com/responses/files/248d7e058a125dd56d1d55f9cf223ca956e998861c278714ec463bf5e3392721/PXL_20210513_135425411.jpg", "scribbleSpace": "Hilarious hema as I call myself! Loves to watch birds anywhere they appear:) loves to connect people from different fields..", "contribution": "I can talk about what birds did to me.", "intro": "Bird enthusiast!"}',
  '{"name": "Leelavati Shetty", "place": "Andheri, Mumbai", "waNumber": "918080599696", "email": "leelavati_shetty@yahoo.co.in", "photoLine": "https://api.typeform.com/responses/files/3dd7599d73b0d4901a7dad33db9aa8ce9844b3feeaf3a6785a42d20f492f90c0/IMG_20191230_141233_2.jpg", "scribbleSpace": "I love to watch thriller movies and wb series. Spending time with kids. Socializing with friends. Plan was to learn swimming but this pandemic didn\'t allow, stay fit so want to be regular with my WO, cook healthy stuffs for kids. Trying my hands on cooking. Want to be more creative, high on energy and fit.", "contribution": "I have regularly good feedback that I can counsel well, so lot of my friends and colleagues do discuss their personal/ family/ kids related challenges. Whatever possible I guide them. Or at least give a hearing ear to them. Sometimes just listening also helps", "intro": "True Friend"}',
  '{"name": "Vinaya Sambargi", "place": "Mumbai", "waNumber": "919869033624", "email": "vinaya.sambargi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3df78b01cfa8f84a687519fa325b612c6f3e781415cb1d0e3d0b48020ccb5efb/update_vinaya.jpeg", "scribbleSpace": "Dance, writing occasionally\\n\\n But can\'t think of anything now", "contribution": "Not sure what I am good at... Anything that takes short time :)", "intro": "A humanist teacher"}',
  '{"name": "Kirteeraj Malkar", "place": "Rajarampuri, Kolhapur", "waNumber": "919405550489", "email": "kirteerajmalkar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5ae79c968ab63c7fab1958ae7e9074fa74236d0a05e09b2758160c8f7176ee4a/Screenshot_20200726_120311.png", "scribbleSpace": "i am passionate Developer, interested in sprituality, meditation and finding meaning of life.", "contribution": "I can share my favorite qoutes, I can share some hacks to wake up early & sleep well", "intro": "Developer"}',
  '{"name": "Siddharth Biniwale", "place": "Sinhagad road, Pune", "waNumber": "919405505902", "email": "sakhasiddha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ef6282ec97a458fc1e5d99860e4192889e7b4791b07ffbd87906de10a1c06c71/IMG_20210430_090722.jpg", "scribbleSpace": "I am a Naturalist and researcher. Wildlife photography and recording bird calls/songs interests me. Exploring wilderness is a way of life for me.", "contribution": "I would like to offer company for morning cycling ride whoever is interested.\\nI would like to share my skills of Birding and photographing birds.", "intro": "Naturalist"}',
  '{"name": "Yamini", "place": "Chennai", "waNumber": "919940112513", "email": "Yaminiher@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0b5090c3bd78f6ffaf0e7068f5a83b7639ecb93ce82ff67a02317c2799014fad/IMG_20210530_WA0000.jpg", "scribbleSpace": "Aspire to wake up early and feel energetic. Like the morning freshness", "contribution": "positive quote", "intro": "Fitness freak. Love you"}',
  '{"name": "Rajeev Nikumbh", "place": "Goregaon, Mumbai", "waNumber": "919167050101", "email": "rajeevnikumbh@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4a6d9c0eb3af117b7540d7aa87ea2e34c7e4b4bdcf856f5ce68483e7ff492994/IMG_6962.jpg", "scribbleSpace": "A sports enthusiast. Love playing and watching volleyball, football & cricket. Any outdoor activities including trekking, exploring new places are always fun. And yes, good movies and music are definitely a best way to enjoy leisure. An investment banker during the day to earn bread and butter. Though I am not a morning person and becoming the one has been wishful thinking since ages.  So, here I am...Very excited...looking forward to meet you all amazing people and lets together throw off the shackles :)", "contribution": "I am happy to talk about sports - technique and game plans and sports motivation. And Lend my professional experience if it could be of any help.", "intro": "Sports enthusiast"}',
  '{"name": "Paras Chhabra", "place": "New Delhi", "waNumber": "919999656662", "email": "paraschhabra96@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6af067514840735d85652a5c2b980622934d1087f4ba97396f985b036229ee82/pic2.jpg", "scribbleSpace": "Have been trying to make waking up early since a long time. But have been failing consistently. Was greatly inspired by the Robin Sharma\'s book 5 AM club but wasn\'t able to put the waking up habit into practice.\\n\\nI think waking up early can help me a lot in most areas of my life like health and career.", "contribution": "I can tell about basics of podcasting - why you should do podcasting, what you need, how to record and publish, etc.", "intro": "Software Engineer"}',
  '{"name": "Bipin Advant", "place": "Bavdhan Pune", "waNumber": "919881491130", "email": "bipinadvant@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1dc7588f1c554d0744d84f14798d28c44f7996fb4566bfb9acb0f09f680b5227/IMG_20210530_234901.jpg", "scribbleSpace": "Music, Meditation and Yoga, Start your day early", "contribution": "Something on personal development, Experience on gratitude and positive thinking", "intro": "IT professional"}',
  '{"name": "Srikanth Ramanujan", "place": "Siruseri Chennai", "waNumber": "919962523984", "email": "sri1990@gmail.com", "photoLine": "https://api.typeform.com/responses/files/79f274b601b04b019f4e844c2d81e00a4267eb327e6ae4e3ca286a69e51c3f6a/1542174607961.jpg", "scribbleSpace": "I love reading, watching films and listening to music. I\'m a finance professional and am looking to have a more disciplined life", "contribution": "I\'m spiritual by nature and I like reading a lot as well, so may be a few quotes or short stories by my favorite authors and their philosophies", "intro": "Finance Professional"}',
  '{"name": "Seema Kalantri", "place": "Bopal, Ahmedabad", "waNumber": "919586499988", "email": "srkalantri@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/41a1e8e834ee6c65d17e926c1c537563e169583fc32401c8726b70ad60ac84a0/IMG_20210101_WA0004.jpg", "scribbleSpace": "Interests - Writing\\nIssues - Irratic work schedule", "contribution": "Professional Development in Information Technology area,\\nSelf Acceptance with Vitiligo", "intro": "Working Woman"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Varun Reddy Sevva", "place": "Kukatpally, Hyderabad", "waNumber": "918500614614", "email": "varun.sevva@iiml.org", "photoLine": "https://api.typeform.com/responses/files/c226584b7e5d2516a337ebbb3261a5ca5085c9158d0e88a0b4887fceab40a4ef/20210101_163839.jpg", "scribbleSpace": "I work as a HR Professional. Married. Quizzing, US Politics and Cooking are my hobbies. Learning to speak Esperanto.", "contribution": "a) The thought process behind setting good quiz questions b) Abour Esperanto and why I am interested in it c) Basics of cooking simple home-made curries d) The US Political System and US Elections", "intro": "Decent Quizzer"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Suvam Roy", "place": "BANGALORE Carmelarem", "waNumber": "919930385272", "email": "suvam.roy@iiml.org", "photoLine": "https://api.typeform.com/responses/files/2d38b84a682f1b34eb9a424514d0392d87f9d975b04bd92ea83c4a9c7830dac0/Pic.jpg", "scribbleSpace": "I am a Product Management Professional . I like to travel and learn new languages", "contribution": "Determination Prioritisation and perseverance", "intro": "Product Guy"}',
  '{"name": "Rutuja Dhamale", "place": "Sindewahi, Chandrapur", "waNumber": "919591574593", "email": "rutuja.dhamale@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b83e74fa6a4c28c93b1595a42941c476c5fb26e25062c668b33de35670919004/WhatsApp_Image_2020_01_07_at_8.31.09_PM.jpeg", "scribbleSpace": "I am a wildlife biologist living in Chandrapur and my hobbies are bird watching, playing the ukelele, painting.", "contribution": "Nothing that I can think of", "intro": "Nature educator, singer"}',
  '{"name": "Rakesh", "place": "Gurgaon", "waNumber": "918527399480", "email": "rakeshsirohia@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/485f9da0072345f6c8873ecd13e3b48ab5a57f8875146d7ec20e9897ecbe987a/IMG_20200326_173312.jpg", "scribbleSpace": "Hindi movies and songs", "contribution": "Love to hear and learn from the group", "intro": ".."}',
  '{"name": "Kavitha", "place": "Kukatpally", "waNumber": "919177063544", "email": "yeruvakavithareddi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f8a3d8cda9c1e14bc1377388c8b1fb9326f913b806d6f710225fd77d339eeb38/IMG_20210405_161228.jpg", "scribbleSpace": "Reading books", "contribution": "Not much", "intro": "Cool person"}',
  '{"name": "Padma", "place": "Chennai", "waNumber": "919444078614", "email": "padmathiagarajan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fe16a6ce8c705ae59745549a0cadf4e2d7d2872eb339264a1f3fc0e491d6153f/1610697823797.jpg", "scribbleSpace": "Chartered accountant  by profession.. Fitness, art, music is my passion..", "contribution": "Importance of being fit.", "intro": "Enthusiastic learner"}',
  '{"name": "Bhoomica Asnani", "place": "Godhbunder Road, Thane West", "waNumber": "919987540067", "email": "bhoomica.asnani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/19755160af485aa4889878231b9c01da8e048820fe9fda41ed60afb50069992f/IMG_20210525_094432.jpg", "scribbleSpace": "I live by the following line- Live like a queen or live like you don\'t care who is the queen! I am interested in being happy and spreading happiness. \\nMy hobbies include reading and watching good movies or series.\\nI am a mother of two lovely children. And life has seasoned me by putting me in various roles. \\nI am presently the Vice Principal of an ICSE School in Thane. \\nI love the colour of the sky during a sunrise and sunset!", "contribution": "Have been into the Education industry for last 15 years. So anything out of that experience if anyone wants I am available \\ud83d\\ude00", "intro": "Seasoned Woman"}',
  '{"name": "Rahul Sharma", "place": "Sahakarnagar, Pune", "waNumber": "918390876388", "email": "rahul.symantec@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a21228e6f6004fdf5eb652c945c2ae0f1a37b252a23d6e0235997fbf7862a575/image.jpg", "scribbleSpace": "Have been working in IT field field since 15 years out of which I was working in Night shift (9:30 PM - 6:30 AM) for last 3 years. This seems to have messed up my body clock. While I am back to regular working hours  now, my expectation is to get into the habit of waking early and going to bed early.", "contribution": "Managing personal finances.", "intro": "People Leader"}',
  '{"name": "Pranav Patil", "place": "Parvati, Pune", "waNumber": "+919822322184", "email": "pensafe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4645023cc8b8a2e68b03a303d396567534ce5388ca5019141e34d1b5cae6d5a1/20210101_175619.jpg", "scribbleSpace": "5\'amers bring a positive change to the World, and happiness to me! I like to have simple friends. Fitness is passion.", "contribution": "Power ideas, thoughts and experiences", "intro": "Happiness coach"}',
  '{"name": "Suyash Nagayach", "place": "Bavdhan, Pune", "waNumber": "919890005923", "email": "suyashnagayach@rediffmail.com", "photoLine": "https://api.typeform.com/responses/files/64d500010ece919d28578a7a15be97d1e24b00c7e1d12b9383d1fe8d5d3e0ad5/WhatsApp_Image_2021_05_31_at_15.14.33.jpeg", "scribbleSpace": "An avid sports lover; likes reading (mostly non-fiction) ; photography is an interest; exploring myself and spirituality", "contribution": "Perspectives from the sports world; Structured presentations ; Lessons I learnt reading some interesting stuff", "intro": "Pragmatic Believer"}'
];

// let newData = [
//   '{"name": "Snehal Bari", "place": "Handewadi Pune", "waNumber": "919284761691", "email": "barisnehal29@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c31190b01f50d1ba3320f53e57c67d19a341ed336ddd15a358cc5756f63a0a12/IMG_20210324_WA0011.jpg", "scribbleSpace": "I like to listen to the people than to speak\\nSelf talkative and over thinking girl\\nNot a usual girl\\nCan be aggressive anytime\\nI love being fit\\nLifetime learner", "contribution": "I can motivate people to be fit", "intro": "Yoga practitioner"}',
//   '{"name": "Arunkumar krishnamoorthy", "place": "Koramangala, Bangalore", "waNumber": "919449804064", "email": "arunram.krish@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0d7ab2a3bf3f1fd45c12dcdc312180598e0e5688d4c25eb3e335da61585bddc0/IMG_20210401_134958.jpg", "scribbleSpace": "Looking forward to get that habbit inculcated and have an efficient and productive day", "contribution": "Sing, exercise, motivate", "intro": "Human being"}',
//   '{"name": "Balaji Mankari", "place": "Banglore", "waNumber": "918125864262", "email": "balajimankari2015@gmail.com", "photoLine": "https://api.typeform.com/responses/files/630687bab5f99e0d0dddf9472da99885a9ed8b39686968514efae5dadd566493/101EFF09_70E8_481A_A4FC_20B8CF327B82.jpeg", "scribbleSpace": "I am director of educational institution and In my free time I will be travelling to Himalayas and I love road trips", "contribution": "Let c what I can contribute", "intro": "Traveller"}',
//   '{"name": "Siddharth Biniwale", "place": "Sinhagad road, Pune", "waNumber": "919405505902", "email": "sakhasiddha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ef6282ec97a458fc1e5d99860e4192889e7b4791b07ffbd87906de10a1c06c71/IMG_20210430_090722.jpg", "scribbleSpace": "I am a Naturalist and researcher. Wildlife photography and recording bird calls/songs interests me. Exploring wilderness is a way of life for me.", "contribution": "I would like to offer company for morning cycling ride whoever is interested.\\nI would like to share my skills of Birding and photographing birds.", "intro": "Naturalist"}',
//   '{"name": "Mahak Karda", "place": "Sigra Varanasi", "waNumber": "919819427080", "email": "mahakkarda9@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a9fd9445e79f5632df3b1cf6750548d40948f23433806bb0b0136479670f0fbc/PicsArt_05_19_10.11.41.jpg", "scribbleSpace": "Globetrotter and travel buff / Fashionista and trendsetter / Dynamic inspirer / originative and ingenious", "contribution": "Meditation techniques / How to find creative solutions to your problems", "intro": "Illuminating verve"}',
//   '{"name": "Preetee pushpa-prakash", "place": "Aundh pune", "waNumber": "919422517129", "email": "opreetee@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ebd6b848a3d5722d9671716bdfe75fed1990fd965c18b6dca6d35085e585e187/IMG20190706174944.jpg", "scribbleSpace": "Gardening, composting, parenting, writing, storytelling, reading, education, environment, biodiversity", "contribution": "How to do the wet waste composting at home", "intro": "Life-long learner"}',
//   '{"name": "Rajeev Nikumbh", "place": "Goregaon, Mumbai", "waNumber": "919167050101", "email": "rajeevnikumbh@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4a6d9c0eb3af117b7540d7aa87ea2e34c7e4b4bdcf856f5ce68483e7ff492994/IMG_6962.jpg", "scribbleSpace": "A sports enthusiast. Love playing and watching volleyball, football & cricket. Any outdoor activities including trekking, exploring new places are always fun. And yes, good movies and music are definitely a best way to enjoy leisure. An investment banker during the day to earn bread and butter. Though I am not a morning person and becoming the one has been wishful thinking since ages.  So, here I am...Very excited...looking forward to meet you all amazing people and lets together throw off the shackles :)", "contribution": "I am happy to talk about sports - technique and game plans and sports motivation. And Lend my professional experience if it could be of any help.", "intro": "Sports enthusiast"}',
//   '{"name": "Bhoomica Asnani", "place": "Godhbunder Road, Thane West", "waNumber": "919987540067", "email": "bhoomica.asnani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/19755160af485aa4889878231b9c01da8e048820fe9fda41ed60afb50069992f/IMG_20210525_094432.jpg", "scribbleSpace": "I live by the following line- Live like a queen or live like you don\'t care who is the queen! I am interested in being happy and spreading happiness. \\nMy hobbies include reading and watching good movies or series.\\nI am a mother of two lovely children. And life has seasoned me by putting me in various roles. \\nI am presently the Vice Principal of an ICSE School in Thane. \\nI love the colour of the sky during a sunrise and sunset!", "contribution": "Have been into the Education industry for last 15 years. So anything out of that experience if anyone wants I am available \\ud83d\\ude00", "intro": "Seasoned Woman"}',
//   '{"name": "Manisha Yadav", "place": "Tavarekere, Bangalore", "waNumber": "918750545040", "email": "maniphilo1202@gmail.com", "photoLine": "https://api.typeform.com/responses/files/191dbef30210ea527256f3aafc4af0ddebda56bac6631984739ced10a8d79eac/IMG20210422130114.jpg", "scribbleSpace": "Looking forward to developing the wake-up habit and learn more about myself. A way to move one step near to oneself.", "contribution": "A few motivational quotes about maintaining health.", "intro": "Philosopher"}',
//   '{"name": "Kavitha", "place": "Kukatpally", "waNumber": "919177063544", "email": "yeruvakavithareddi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f8ce264e077fbdc515897dfbef2a037286e12ba03a61bbb56917b13c98411e1c/IMG_20210405_161228.jpg", "scribbleSpace": "Very normal person", "contribution": "Not much", "intro": "Interest in sports"}',
//   '{"name": "Dhaval (\\u0927\\u0935\\u0932)", "place": "Domlur, Bangalore", "waNumber": "918375029336", "email": "dhavalchudasama@gmail.com", "photoLine": "https://api.typeform.com/responses/files/316f3dd9b2b6b3501f624106e89fbf56e36735db2832bae6b5b11a8206f10b75/A46B3B3E_8DD7_4F07_9E33_14CE65415628_1_201_a.jpeg", "scribbleSpace": "https://dhavalchudasama.com/", "contribution": "Let\'s co-learn during the June camp journey :)", "intro": "Curious Traveller"}',
//   '{"name": "Suvam roy", "place": "Sarjapur Bangalore", "waNumber": "919930385272", "email": "suvam.roy@iiml.org", "photoLine": "https://api.typeform.com/responses/files/ed2334b6c750ed7b936741be5015abcbd6214ae36f8be01fa9aaf10dfe93d47c/Passport_Pic.jpg", "scribbleSpace": "I like to travel. Learn new languages. And I Am a pet parent", "contribution": "None", "intro": "Product guy"}',
//   '{"name": "Rahul Sharma", "place": "Sahakarnagar, Pune", "waNumber": "918390876388", "email": "rahul.symantec@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a21228e6f6004fdf5eb652c945c2ae0f1a37b252a23d6e0235997fbf7862a575/image.jpg", "scribbleSpace": "Have been working in IT field field since 15 years out of which I was working in Night shift (9:30 PM - 6:30 AM) for last 3 years. This seems to have messed up my body clock. While I am back to regular working hours  now, my expectation is to get into the habit of waking early and going to bed early.", "contribution": "Managing personal finances.", "intro": "People Leader"}',
//   '{"name": "Paras Chhabra", "place": "New Delhi", "waNumber": "919999656662", "email": "paraschhabra96@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6af067514840735d85652a5c2b980622934d1087f4ba97396f985b036229ee82/pic2.jpg", "scribbleSpace": "Have been trying to make waking up early since a long time. But have been failing consistently. Was greatly inspired by the Robin Sharma\'s book 5 AM club but wasn\'t able to put the waking up habit into practice.\\n\\nI think waking up early can help me a lot in most areas of my life like health and career.", "contribution": "I can tell about basics of podcasting - why you should do podcasting, what you need, how to record and publish, etc.", "intro": "Software Engineer"}',
//   '{"name": "Yogesh Bhosale", "place": "Chinchwad Pune", "waNumber": "919960449066", "email": "yogesh.bhosale1985@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2e85a69b437200eae09137e9db69c74d3b5b9aa3762f5618417ff46d2ccd3d3a/SAVE_20210220_230213.jpg", "scribbleSpace": "I like to read,travel and help other people. My expectations are I will make a habbit to wake up 5am everyday with this programme.!", "contribution": "Not sure but would like to contribute.", "intro": "Travel Freak"}',
//   '{"name": "Yamini", "place": "Chennai", "waNumber": "919940112513", "email": "Yaminiher@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0b5090c3bd78f6ffaf0e7068f5a83b7639ecb93ce82ff67a02317c2799014fad/IMG_20210530_WA0000.jpg", "scribbleSpace": "Aspire to wake up early and feel energetic. Like the morning freshness", "contribution": "positive quote", "intro": "Fitness freak. Love you"}',
//   '{"name": "Anusha Yeruva", "place": "Hyderbad", "waNumber": "917036449449", "email": "anshuyeruva@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a114c6a45e36895d6546cfc944140aeef7410bd5bc6f78270a744e149b632689/Screenshot_20210521_195038_Photos_2.jpg", "scribbleSpace": "I am intrested in sustainable living.\\n\\nI love nature walks, take fresh air and organic diet,  which helps me to he fit and healthy.\\n\\nI am looking forward to know myself and my intrests with the help of this club.", "contribution": "I am not sure yet, but I can motivate people by my writings, yet looking forward to do something new which can help me know myself well.", "intro": "I am a yoga practitioner and  a nature lover."}',
//   '{"name": "Kalpesh Dhruve", "place": "Pimple Saudagar, Pune", "waNumber": "919923145886", "email": "kdhruve2@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ea83a0c26ddf8b60eb285cbadb84e08531c0a59f7fa76597a26ece43fc7106a6/20210102_120936.jpg", "scribbleSpace": "Outdoor Sports, Making short phrases/Rhymes, Working towards redeeming myself", "contribution": "Financial, Investment Advisory,", "intro": "Practising Chartered Accountant"}',
//   '{"name": "Manish Kulkarni", "place": "Chinchwad Pune 33", "waNumber": "918806562999", "email": "manishexc@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2104daa033e6dd3f6bc68f6ad11166f2e8525ec318b35ea15c0cc2ce869b02c5/IMG_20200704_WA0037.jpg", "scribbleSpace": "Want to take out some time for self", "contribution": "Will try to give some different prospective", "intro": "The family man"}',
//   '{"name": "Seema Kalantri", "place": "Bopal, Ahmedabad", "waNumber": "919586499988", "email": "srkalantri@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/41a1e8e834ee6c65d17e926c1c537563e169583fc32401c8726b70ad60ac84a0/IMG_20210101_WA0004.jpg", "scribbleSpace": "Interests - Writing\\nIssues - Irratic work schedule", "contribution": "Professional Development in Information Technology area,\\nSelf Acceptance with Vitiligo", "intro": "Working Woman"}',
//   '{"name": "Renu Shirokha", "place": "Mumbai", "waNumber": "919004087387", "email": "rshirokha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6f0139a6cdcff3e995d15abce2ab2cf40095526fd4c2e1819956cce7af0ccb58/603BFBEB_4733_4023_B4C8_C84F32DD982A.jpeg", "scribbleSpace": "I m architect as well as artist. My hobbies are reading, travelling and playing with my dog. My plans are concentrate my growth in self development as much as possible got this year.. looking forward to meet you all soon", "contribution": "Well, i would like share the more we look within ourself .. everything falls on it places on outside", "intro": "Architect & artist"}',
//   '{"name": "Siddharth Biniwale", "place": "Sinhagad road", "waNumber": "919405505902", "email": "sakhasiddha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1167d0c2e3e0a9948d7fa0c703cbef6353f64f3d213dc71eb13fd304549c130b/IMG_20210430_090722.jpg", "scribbleSpace": "I am a Naturalist. Wildlife photography, recording bird calls/songs interests me.", "contribution": "I can teach Suryanamaskar. \\n\\nI would like to give company for morning cycling ride if anyone interested.", "intro": "Naturalist"}',
//   '{"name": "Raman Bhatia", "place": "Gurgaon", "waNumber": "919654135441", "email": "toramanbhatia@gmail.com", "photoLine": "https://api.typeform.com/responses/files/68e28ba37cc301b96331af279a8c37f30a9b3c5f3b387ae7c2cd97e34142e624/9C67A19D_99B6_4669_A3A0_5C1A64CC2BB9.jpeg", "scribbleSpace": "Better be awake till 5 am", "contribution": "Life is short, make the most of it", "intro": "Sleep Lover"}',
//   '{"name": "Shilpa Gaur", "place": "Makrana Rajasthan", "waNumber": "919794378287", "email": "shilpa020489@gmail.com", "photoLine": "https://api.typeform.com/responses/files/78c34a36457b354f74fc0d396098e0aaa08f3e6d6e7f0c8746ea785584143142/E0D59C16_1EB9_45FC_9C1D_BB89F55CDE93.jpeg", "scribbleSpace": "Reading, Want to get fit and get more done everyday", "contribution": "Functionally I can help in marketing and strategy related things. I work with a gaming studio so anyone looking to understand about this area can connect with me. I can help buddy up with people in micro projects for a week ( like finishing a small online course or book in a week or small sprint like not eating junk etc). I can be a good responsibility partner.", "intro": "Habitual Reader"}',
//   '{"name": "Umesh Kedari", "place": "Katraj Pune", "waNumber": "919850355691", "email": "umesh.kedari@gmail.com", "photoLine": "https://api.typeform.com/responses/files/72e716e345216a58fdd9f46ef50ef577fba33868fb139f3cea7bbaeb285ae1e1/IMG_20200521_094042.jpg", "scribbleSpace": "Listening Indian classical music, Gardening", "contribution": "Experience", "intro": "Mechanical Engineer"}',
//   '{"name": "Gunjan Aggarwal", "place": "HSR Layout, Bangalore", "waNumber": "919833263624", "email": "gunjan1982@gmail.com", "photoLine": "https://api.typeform.com/responses/files/be5549086170725cb10d3cf7861b268a8976e8601ae8ad98b0d8fe8ceb009a3f/1622406352148681389425.jpg", "scribbleSpace": "Bookworm and movie geek", "contribution": "A new crypto enthusiast", "intro": "Crypto geek"}',
//   '{"name": "Bipin Advant", "place": "Bavdhan Pune", "waNumber": "919881491130", "email": "bipinadvant@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1dc7588f1c554d0744d84f14798d28c44f7996fb4566bfb9acb0f09f680b5227/IMG_20210530_234901.jpg", "scribbleSpace": "Music, Meditation and Yoga, Start your day early", "contribution": "Something on personal development, Experience on gratitude and positive thinking", "intro": "IT professional"}',
//   '{"name": "Whats your name ? ", "place": "Hi {{field:489873bb-e26c-48c7-b3aa-b774c5e46c03}}, Whats your  area, city? ", "waNumber": "ats-app number", "email": "Email ", "photoLine": "Upload your profile photo For club wall", "scribbleSpace": "Scribble space to express yourself: ", "contribution": "Contribution to know what you can offer to others in coming 21 days: ", "intro": "Your intro in two words: "}',
//   '{"name": "Babasaheb", "place": "Pune", "waNumber": "919921002346", "email": "rengebm@gmail.com", "photoLine": "https://api.typeform.com/responses/files/056aab00ac7ffa27aab45febbab09e32c70f893866e097020f58b17c42d4dcda/188978388_4249895371709094_3684729620686480810_n.jpg", "scribbleSpace": "5am", "contribution": "NA", "intro": "Thanks"}',
//   '{"name": "Kavitha", "place": "Kukatpally", "waNumber": "919177063544", "email": "yeruvakavithareddi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f8a3d8cda9c1e14bc1377388c8b1fb9326f913b806d6f710225fd77d339eeb38/IMG_20210405_161228.jpg", "scribbleSpace": "Reading books", "contribution": "Not much", "intro": "Cool person"}',
//   '{"name": "Ayan Sengupta", "place": "Kolkata", "waNumber": "919088186558", "email": "ayanse94@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4302dc702060525344a5487054f5f35ba8787939fce35afa8c0bc5f2119eb32f/IMG_20210404_175012.jpg", "scribbleSpace": "Interests to wake up early morning.", "contribution": "Meditation", "intro": "I am a digital marketing expert"}',
//   '{"name": "Leena Patil", "place": "Mumbai Mulund", "waNumber": "919820383123", "email": "leenapatil@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/93a120549a166b0cdf3cd71a92e8de0656c5da275fe09b00e2a8508317a87d1a/IMG_20170505_124933424_HDR.jpg", "scribbleSpace": "Optimistic!!!!", "contribution": "Happy to help!!!", "intro": "Lawyer!!!!!"}',
//   '{"name": "Rakesh", "place": "Gurgaon", "waNumber": "918527399480", "email": "rakeshsirohia@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/485f9da0072345f6c8873ecd13e3b48ab5a57f8875146d7ec20e9897ecbe987a/IMG_20200326_173312.jpg", "scribbleSpace": "Hindi movies and songs", "contribution": "Love to hear and learn from the group", "intro": ".."}',
//   '{"name": "Hema", "place": "Mumbai", "waNumber": "919004261133", "email": "hema.sagare@gmail.com", "photoLine": "https://api.typeform.com/responses/files/248d7e058a125dd56d1d55f9cf223ca956e998861c278714ec463bf5e3392721/PXL_20210513_135425411.jpg", "scribbleSpace": "Hilarious hema as I call myself! Loves to watch birds anywhere they appear:) loves to connect people from different fields..", "contribution": "I can talk about what birds did to me.", "intro": "Bird enthusiast!"}',
//   '{"name": "Abhishek Anand", "place": "Delhi NCR", "waNumber": "918800373555", "email": "abhishek.x.anand@iiml.org", "photoLine": "https://api.typeform.com/responses/files/9035e146cb216cdb148787bbc0bdb03f58a48f1f14d29c68fd07eeb9ed32074a/Screenshot_20210101_214311_01.jpeg", "scribbleSpace": "Photography, reading. Love to explore the artistic side.", "contribution": "Will think through", "intro": "Love photography."}',
//   '{"name": "Vinaya Sambargi", "place": "Mumbai", "waNumber": "919869033624", "email": "vinaya.sambargi@gmail.com", "photoLine": "https://firebasestorage.googleapis.com/v0/b/amclub-cd890.appspot.com/o/campaignPhotos%2FWhatsApp%20Image%202021-04-29%20at%2019.40.19.jpeg?alt=media&token=85657abb-7c45-466e-8c04-7d65ee0a17d6", "scribbleSpace": "Dance, writing occasionally\\n\\n But can\'t think of anything now", "contribution": "Not sure what I am good at... Anything that takes short time :)", "intro": "A humanist teacher"}',
//   '{"name": "Madhura Rale", "place": "Aranyeshwar, Pune", "waNumber": "919860347975", "email": "madhura76@gmail.com", "photoLine": "https://api.typeform.com/responses/files/17aa005a61a9154c4472cff48550c4595d5df2d5316e2ef7844ac04d37fe13a5/Screenshot_20210101_193848_WhatsApp.jpg", "scribbleSpace": "A mad curious person", "contribution": "Not sure... currently in receiving mode", "intro": "Curious biochemist"}',
//   '{"name": "Pranav Patil", "place": "Parvati, Pune", "waNumber": "+919822322184", "email": "pensafe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4645023cc8b8a2e68b03a303d396567534ce5388ca5019141e34d1b5cae6d5a1/20210101_175619.jpg", "scribbleSpace": "5\'amers bring a positive change to the World, and happiness to me! I like to have simple friends. Fitness is passion.", "contribution": "Power ideas, thoughts and experiences", "intro": "Happiness coach"}',
//   '{"name": "Kirteeraj Malkar", "place": "Rajarampuri, Kolhapur", "waNumber": "919405550489", "email": "kirteerajmalkar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5ae79c968ab63c7fab1958ae7e9074fa74236d0a05e09b2758160c8f7176ee4a/Screenshot_20200726_120311.png", "scribbleSpace": "i am passionate Developer, interested in sprituality, meditation and finding meaning of life.", "contribution": "I can share my favorite qoutes, I can share some hacks to wake up early & sleep well", "intro": "Developer"}',
//   '{"name": "Varun Reddy Sevva", "place": "Kukatpally, Hyderabad", "waNumber": "918500614614", "email": "varun.sevva@iiml.org", "photoLine": "https://api.typeform.com/responses/files/c226584b7e5d2516a337ebbb3261a5ca5085c9158d0e88a0b4887fceab40a4ef/20210101_163839.jpg", "scribbleSpace": "I work as a HR Professional. Married. Quizzing, US Politics and Cooking are my hobbies. Learning to speak Esperanto.", "contribution": "a) The thought process behind setting good quiz questions b) Abour Esperanto and why I am interested in it c) Basics of cooking simple home-made curries d) The US Political System and US Elections", "intro": "Decent Quizzer"}',
//   '{"name": "Pallavi Dhoot", "place": "Senapati Bapat Marg, Pune", "waNumber": "919822008007", "email": "pallavidhoot.nature@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f562524e23ca1314e06cf69db8e1daa6423eb810c7330f9255f984df68d901fa/Screenshot_20210104_042823.png", "scribbleSpace": "I am an early riser.But getting up every day exactly at the same time is something I am trying for the first time.  A dedicated mother and wife, a passionate Naturopath and researcher, a sustainable lifestyle explorer, is how I describe myself.", "contribution": "Can share my knowledge, thoughts and experiences", "intro": "An Explorer"}',
//   '{"name": "Poorva Joshi", "place": "Sinhagad road , Pune", "waNumber": "919604289893", "email": "poorva98joshi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/41cf37d2d8339f601af614d7b6662640753f3a8e175843c54a8a1c56aa6f4cc4/IMG_20210201_WA0000_2.jpg", "scribbleSpace": "I\'m a medical student , in my last year of college . I\'m studying Ayurveda. Honestly I\'ve never been someone who wakes up early . It really is a task for me \\ud83d\\ude1band I have this habit of snoozing my alarms and I end up sleeping again . I realise that I\'m preaching something that I\'m not practicing myself as a doctor ,inspite of knowing the importance of waking up early.  Hence I\'m more than happy and excited to be a part of the 5 am club!", "contribution": "I can talk about the routine or the \'dincharya\' that\'s mentioned in the ayurvedic texts", "intro": "Ayurveda student"}',
//   '{"name": "Suyash Nagayach", "place": "Bavdhan, Pune", "waNumber": "919890005923", "email": "suyashnagayach@rediffmail.com", "photoLine": "https://dd-reciepts.s3.ap-south-1.amazonaws.com/5am/WhatsApp+Image+2021-05-31+at+15.14.33.jpeg", "scribbleSpace": "An avid sports lover; likes reading (mostly non-fiction) ; photography is an interest; exploring myself and spirituality", "contribution": "Perspectives from the sports world; Structured presentations ; Lessons I learnt reading some interesting stuff", "intro": "Pragmatic Believer"}',
//   '{"name": "Padma", "place": "Chennai", "waNumber": "919444078614", "email": "padmathiagarajan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fe16a6ce8c705ae59745549a0cadf4e2d7d2872eb339264a1f3fc0e491d6153f/1610697823797.jpg", "scribbleSpace": "Chartered accountant  by profession.. Fitness, art, music is my passion..", "contribution": "Importance of being fit.", "intro": "Chartered accountant by profession.. Fitness, art and music is my passion.."}',
//   '{"name": "Debanjana Sinha", "place": "Domlur, Bangalore", "waNumber": "919911777199", "email": "debu.piyu@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ea4662680ab24a3542356d467a3c544159ed5a711c11e32fece168fabf5e5f9a/IMG_20190913_184013.jpg", "scribbleSpace": "Interest- Stoicism, Japan, Philosophy, fountain pens, stationery\\nExpectations- hope to be a morning person through this person", "contribution": "General trivia/ quizzes, Stoic philosophy, historical trivia, travelling tips", "intro": "Memento Mori"}',
//   '{"name": "Leena Patil", "place": "Mulund East", "waNumber": "919820383123", "email": "leenapatil@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/e898a02e03939698e31d8e9f44dcd702b1398dff2bc00eaca9ceb4ca9fbda738/IMG_20210123_183046.jpg", "scribbleSpace": "I am an optimistic person! Want to be always happy and makes other happy too!", "contribution": "Want to help everyone in whatever manner I can!", "intro": "I am a lawyer!"}',
//   '{"name": "Manisha Yadav", "place": "Panchgani, Mahabaleshwar", "waNumber": "918750545040", "email": "maniphilo1202@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3cb802378c2023d2cc4cc99d6ee4dfe8ed42a6b43f1bdf604b61e7f8bf7a7959/IMG20210324100110.jpg", "scribbleSpace": "I like listening music, little dancing, lots of talking. Also, I am very excited to develope this amazing habit of 5 am", "contribution": "Self love Quotes", "intro": "Philosopher"}'
// ];


var takenOut = [
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Arunkumar krishnamoorthy", "place": "Koramangala, Bangalore", "waNumber": "919449804064", "email": "arunram.krish@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0d7ab2a3bf3f1fd45c12dcdc312180598e0e5688d4c25eb3e335da61585bddc0/IMG_20210401_134958.jpg", "scribbleSpace": "Looking forward to get that habbit inculcated and have an efficient and productive day", "contribution": "Sing, exercise, motivate", "intro": "Human being"}',
  '{"name": "Babasaheb", "place": "Pune", "waNumber": "919921002346", "email": "rengebm@gmail.com", "photoLine": "https://api.typeform.com/responses/files/056aab00ac7ffa27aab45febbab09e32c70f893866e097020f58b17c42d4dcda/188978388_4249895371709094_3684729620686480810_n.jpg", "scribbleSpace": "5am", "contribution": "NA", "intro": "Thanks"}',
]